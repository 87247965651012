import React from "react";
import { Link } from "gatsby";
//@ts-ignore
import * as styles from "./MegaHeader.module.css"
import { useTranslation } from "./localization";
import { Group, Drawer, ScrollArea, rem, Divider, Anchor, Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

export function AppMenu() {
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const { t } = useTranslation()

    return <>
        <Group h="100%" visibleFrom="sm" ml={'auto'} gap={30}>
            <Link to="/about" className={styles.link}>
                {t('About')}
            </Link>
            <Link to="/parent" className={styles.link}>
                {t('Parent')}
            </Link>
            <Link to="/partner" className={styles.link}>
                {t('Partner')}
            </Link>
        </Group>
        <Drawer opened={drawerOpened} onClose={closeDrawer} size="100%" padding="md" title="Navigation" hiddenFrom="sm" zIndex={1000000}>
            <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
                <Divider my="sm" />

                <Anchor href="#">
                    Learn
                </Anchor>
                <Anchor href="#">
                    Academy
                </Anchor>

                <Divider my="sm" />

                <Group justify="center" grow pb="xl" px="md">
                    <Button variant="default">Log in</Button>
                    <Button>Sign up</Button>
                </Group>
            </ScrollArea>
        </Drawer>
    </>
}